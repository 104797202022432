<template>
    <div class="content md:mx-0 md:mt-0 md:mb-0">
        <div class="desc-content">
            <div class="content-wrap md:mx-12">
                <div class="flex items-center">
                    <span class="text-dot"></span>
                    <span class="question">{{ lang == 'en' ? 'Technical architectureand innovation' : '技術架構與創新' }}</span>
                </div>
                <div class="flex md:block">
                    <div class="answer flex-1">
                        {{ lang == 'en' ? 'Unbelievable' : '令人難以置信的' }}
                        <br />
                         {{ lang == 'en' ? 'Absolutely' : '絕對優勢' }}
                    </div>
                    <div class="right-desc md:relative md:mt-12">{{ lang == 'en' ? "VitalTech's platform positioning is centered on the global market, and it is committed to breaking geographical restrictions and achieving truly global e-commerce and direct sales services." : 'VitalTech的平臺定位以全球市場為核心，致⼒於打破地域限製，實現真正的全球電商和直銷服務。' }}</div>
                </div>
            </div>
        </div>
        <div class="introduce-content md:block md:px-12 md:mt-40">
            <div class="flex-1">
                <div class="box-item active item-1 flex-1 md:mb-20">
                    <div class="text"> {{ lang == 'en' ? 'Distributed Ledger Technology' : '分布式賬本技術' }}</div>
                    <div class="desc"> {{ lang == 'en' ? 'Decentralized data storage、Transaction transparency、Integration of smart contracts' : '去中⼼化的數據存儲、交易透明性、智能合約的集成' }}</div>
                    <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/introduce-1.png" alt="" />
                </div>
                <div class="box-item item-4 md:mb-20">
                    <div class="text"> {{ lang == 'en' ? 'Decentralized Applications' : '去中⼼化應⽤' }}</div>
                    <div class="desc">{{ lang == 'en' ? 'Decentralized application architecture、User autonomy、Transparent operation process' : '去中⼼化的應⽤架構、⽤⼾⾃主控製、透明的操作流程' }}</div>
                    <div @click="$router.push({ name: 'ChipRaw' })">
                        <Button :class="$lang">{{ lang == 'en' ? 'Learn more' : '了解更多' }}</Button>
                    </div>
                    <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/introduce-3.png" alt="" />
                </div>
            </div>
            <div class="flex-1">
                <div class="box-item item-2 md:mb-20">
                    <div class="text">{{ lang == 'en' ? 'Smart Contracts' : '智能合約' }}</div>
                    <div class="desc">{{ lang == 'en' ? 'Automated transaction processing、Member Rewards System、Contract management' : '⾃動化交易處理、會員獎勵系統、合同管理' }}</div>
                    <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/introduce-2.png" alt="" />
                </div>
                <div class="box-item item-3">
                    <div class="text">{{ lang == 'en' ? 'Intelligent recommendation system' : '智能推薦系統' }}</div>
                    <div class="desc">{{ lang == 'en' ? 'Personalized recommendation algorithm、Real-time data processing、Deep learning model' : '個性化推薦算法、實時數據處理、深度學習模型' }}</div>
                    <div @click="$router.push({ name: 'ZkpAi' })">
                      <Button :class="$lang">{{ lang == 'en' ? 'Learn more' : '了解更多' }}</Button>
                    </div>
                    <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/introduce-3.png" alt="" />
                </div>
            </div>
        </div>
        <div class="introduce-content md:block md:px-12 md:mt-40">
            <div class="" style="flex: 1;" :class="[screenWidth>800?'flex-between':'flex-1']">
                <div class="box-item item-4 md:mb-20" style="flex: 1;">
                    <div class="text">{{ lang == 'en' ? 'Data-driven personalized services' : '數據驅動的個性化服務' }}</div>
                    <div class="desc">{{ lang == 'en' ? 'User portrait analysis、Personalized marketing、Behavioral prediction' : '⽤⼾畫像分析、個性化營銷、⾏為預測' }}</div>
                    <div @click="$router.push({ name: 'ZkpAi' })">
                      <Button :class="$lang">{{ lang == 'en' ? 'Learn more' : '了解更多' }}</Button>
                    </div>
                    <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/introduce-3.png" alt="" />
                </div>
                <div class="box-item item-4" style="flex: 1;">
                    <div class="text">{{ lang == 'en' ? 'Predictive analytics and market insights' : '預測分析與巿場洞察' }}</div>
                    <div class="desc">{{ lang == 'en' ? 'Sales Forecasting、Market trend analysis、Risk forecasting and management' : '銷售預測、市場趨勢分析、⾵險預測與管理' }}</div>
                    <div @click="$router.push({ name: 'ZkpAi' })">
                      <Button :class="$lang">{{ lang == 'en' ? 'Learn more' : '了解更多' }}</Button>
                    </div>
                    <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/introduce-3.png" alt="" />
                </div>
            </div>
        </div>
        <div class="header md:pt-28">
            <div class="text font-bold md:pl-22 md:text-20">{{ lang == 'en' ? 'VitalTech' : 'VitalTech' }}</div>
            <div>
                <div class="desc md:ml-22 md:text-12 md:mt-8 md:pr-20">
                  {{ lang == 'en' ? "VitalTech's technology architecture is based on advanced Web3 technology and artificial intelligence, combined with strict security measures, and is committed to providing an efficient, secure and personalized e-commerce experience." : 'VitalTech的技術架構以先進的Web3技術和⼈⼯智能為核⼼，結合嚴密的安全措施，致⼒於提供⾼效、安全、個性化的電商體驗。' }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/Button.vue';
import { mapState } from 'vuex';
export default {
    components: {
        Button,
    },
    computed: {
        ...mapState(['lang','screenWidth']),
    },
};
</script>

<style lang="scss" scoped>
.content {
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    z-index: 100;
}
.desc-content {
    position: relative;
    /* top: -100px; */
    top: 0;
    overflow: hidden;
    max-width: 1920px;
    margin: 0 auto;
    margin-bottom: 100px;

    @screen md {
        top: 0 !important;
        margin-top: 69px;
    }

    @media screen and (max-width: 1440px) {
        /* top: -50px; */
        margin-top: 40px;
    }

    .content-wrap {
        position: relative;
        margin: 0 75px;

        @media screen and (min-width: 2070px) {
            margin: 0;
        }
    }

    .text-dot {
        width: 6px;
        height: 6px;
        display: block;
        border-radius: 6px;
        background-color: #253bff;
        margin-right: 7px;
    }

    .question {
        font-size: 14px;
        color: #ffffff;
    }

    .answer {
        font-size: 54px;
        line-height: 68px;
        font-weight: 600;
        color: #ffffff;
        max-width: 45%;

        @screen md {
            max-width: 100%;
            font-size: 24px !important;
            line-height: 32px !important;
        }

        @media screen and (max-width: 1440px) {
            font-size: 40px;
            line-height: 48px;
        }

        @media screen and (max-width: 1200px) {
            font-size: 28px;
            line-height: 36px;
        }
    }

    .right-desc {
        font-size: 20px;
        color: rgba(255, 255, 255, 0.6);
        width: 40%;
        position: absolute;
        bottom: 0;
        right: 0;

        @screen md {
            width: 100%;
            font-size: 12px !important;
        }

        @media screen and (max-width: 1440px) {
            font-size: 16px;
        }

        @media screen and (max-width: 1200px) {
            font-size: 14px;
        }
    }
}
.introduce-content {
    // max-width: 1770px;
    margin: 0 auto;
    display: flex;
    padding: 0 75px;
    max-width: 1920px;
    margin-bottom: 66px;

    @media screen and (min-width: 2070px) {
        padding: 0;
    }
    .flex-1 {
        display: flex;
        flex-direction: column;
    }
    .box-item {
        // flex: 1;
        border-radius: 20px;
        border: 1px solid #2e2a41;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        cursor: pointer;

        &::after {
            content: '';
            display: block;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            position: absolute;
            transform: rotateZ(180deg);
            border-radius: 20px;

            background: radial-gradient(80.07% 90.07% at 50% 100%, #000000 10%, rgba(4, 1, 19, 1) 39.26%, rgba(61, 84, 202, 0.3) 100%),
                linear-gradient(0deg, rgba(46, 42, 65, 0.9), rgba(46, 42, 65, 0.1)), linear-gradient(180deg, #d7ccf6 -4.51%, rgba(19, 8, 48, 0) 52.91%);
            z-index: 1;
            // display: none;
            opacity: 0;
            transition: opacity 3s cubic-bezier(0.26, 1.04, 0.54, 1);
        }

        .text {
            font-size: 32px;
            font-weight: bold;
            line-height: 38px;
            color: white;
            padding-left: 67px;
            padding-top: 47px;
            transition: all 0.3s;
            position: relative;
            z-index: 2;
            max-width: 300px;

            @screen md {
                padding-left: 23px;
                padding-top: 28px;
                font-size: 20px;
                line-height: 24px;
            }
        }

        .desc {
            font-size: 16px;
            line-height: 24px;
            margin-top: 37px;
            // max-width: 473px;
            color: #a0a3ac;
            padding-left: 67px;
            transition: all 0.3s;
            position: relative;
            z-index: 2;
            max-width: 640px;
            margin-bottom: 29px;
            @screen md {
                font-size: 12px;
                padding: 0 23px;
                margin-top: 8px;
                line-height: 20px;
            }
        }
        .c-button {
            margin-left: 67px;
            @screen md {
                margin-left: 23px;
            }
        }

        &:hover,
        &.active {
            &::after {
                // display: block;
                opacity: 1;
            }
            .text {
                color: transparent;
                background-clip: text;
                display: inline-block;
                background-image: radial-gradient(98.96% 1459.2% at 102.6% 50%, #33c4f2 0%, #1f35fd 100%);
            }

            .desc {
                color: white;
            }
        }

        &.item-1 {
            height: 320px;
            margin-right: 50px;
            margin-bottom: 34px;

            @screen md {
                height: 293px;
                margin-right: 0;
            }

            img {
                position: absolute;
                right: -90px;
                bottom: -30px;
            }
        }

        &.item-2 {
            border: 1px solid #2e2a41;
            min-height: 274px;
            border-radius: 20px;
            margin-bottom: 34px;

            img {
                position: absolute;
                right: 14px;
                top: 0;
            }
        }

        &.item-3 {
            min-height: 347px;
            padding-bottom: 30px;
            img {
                position: absolute;
                right: 14px;
                bottom: 0;
            }
            &::after {
                display: none;
            }
        }
        &.item-4 {
            min-height: 298px;
            margin-right: 50px;
            padding-bottom: 30px;
            @screen md {
                height: 293px;
                margin-right: 0;
            }
            img {
                position: absolute;
                right: 14px;
                bottom: 0;
            }
            &:hover {
                &::after {
                    display: none;
                }
            }
        }
    }
}
.header {
    height: 156px;
    width: 100%;
    background-image: url('https://s3.us-west-1.amazonaws.com/agi.love/img/dapp-header.png');
    position: relative;
    z-index: 100;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 20px;
    background-size: cover;
    margin-bottom: 200px;

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @screen md {
        display: block;
        background-image: url('https://s3.us-west-1.amazonaws.com/agi.love/img/dapp-header-m.png');
        height: 293px;
    }

    div {
        flex: 1;

        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #a0a3ac;
    }

    .text {
        font-size: 32px;
        line-height: 38px;
        color: white;
        width: 874px;
        padding-left: 57px;

        @screen md {
            width: auto;
        }
    }

    .desc {
        max-width: 480px;
        margin-left: 64px;
    }
}
</style>
