<template>
    <div class="c-solution md:mx-0 md:mt-0 md:mb-0">
        <div class="content">
            <div class="title-info md:px-12">
                <div class="flex items-center">
                    <span class="dot"></span>
                    {{ lang == 'en' ? 'Founding Team' : '创始团队' }}
                </div>
                <div class="title font-bold">{{ lang == 'en' ? 'Team & Partner' : '團隊及合作夥伴' }}</div>
                <div class="desc">
                    {{
                        lang == 'en'
                            ? "Vitalrech's core team brings together top talent from around the globe with a wealth of industry experience and technical expertise. Our founders and management come from Asia, Europe and Southeast Asia, representing an international perspective and a deep business background."
                            : 'Vitalrech的核心團隊彙聚了來自全球的頂尖人才，擁有豐富的行業經驗和技術專長。 我們的創始人和管理層來自亞洲、歐洲和東南亞代表了國際化視角和深厚的業務背景。'
                    }}
                </div>
            </div>

            <div class="list-wrap md:mx-12">
                <div class="item-content">
                    <div class="items">
                        <img src="../../assets/img/photo/01.png" alt="" />
                        <div class="item-title">{{ lang == 'en' ? 'Max von Schmidt(CEO)' : 'Max von Schmidt(CEO)' }}</div>
                        <div class="description">
                            {{ lang == 'en' ? 'CEO, founder of AJ Labs, and one of the co-founders of Coinbase.' : '⾺克斯•馮•施密特是VitalTechCoin的創始⼈之⼀，擔任⾸席執⾏官。' }}
                        </div>
                    </div>
                    <div class="items md:mt-60">
                        <img src="../../assets/img/photo/02.png" alt="" />
                        <div class="item-title">{{ lang == 'en' ? 'Alicia Chen(CTO)' : 'Alicia Chen(CTO)' }}</div>
                        <div class="description">
                            {{
                                lang == 'en'
                                    ? "Alicia Chen is the Chief Technology Officer of VitalTechCoin, responsible for the platform's technology architecture and innovation."
                                    : '艾莉西亞•陳是VitalTechCoin的⾸席技術官，負責平臺的技術架構和創新。'
                            }}
                        </div>
                    </div>
                </div>
                <div class="item-content mt-80 md:mt-60">
                    <div class="items">
                        <img src="../../assets/img/photo/03.png" alt="" />
                        <div class="item-title">{{ lang == 'en' ? 'Alexander Dupont(COO)' : 'Alexander Dupont(COO)' }}</div>
                        <div class="description">
                            {{
                                lang == 'en'
                                    ? "Alexandre DuPont is the Chief Operating Officer of VitalTechCoin, responsible for the company's global operations and market expansion strategy."
                                    : '亞歷⼭⼤•杜邦擔任VitalTechCoin的⾸席運營官，負責公司全球運營和市場拓展策略。'
                            }}
                        </div>
                    </div>
                    <div class="items md:mt-60 md:pb-60">
                        <img src="../../assets/img/photo/04.png" alt="" />
                        <div class="item-title">{{ lang == 'en' ? 'Mei-Li Lin(CFO)' : 'Mei-Li Lin(CFO)' }}</div>
                        <div class="description">
                            {{
                                lang == 'en'
                                    ? "Mei-Li Lin is the Chief Financial Officer of VitalTechCoin, responsible for the company's financial strategy and capital management."
                                    : 'Mei-Li Lin是VitalTechCoin的⾸席財務官，負責公司的財務戰略和资本管理。'
                            }}
                        </div>
                    </div>
                </div>
            </div>
            <img class="bg-1 md:hidden" src="https://s3.us-west-1.amazonaws.com/agi.love/img/solution-bg-1.png" alt="" />
            <img class="bg-1-m hidden md:block" src="https://s3.us-west-1.amazonaws.com/agi.love/img/solution-bg-m-1.png" alt="" />
            <img class="bg-2" src="https://s3.us-west-1.amazonaws.com/agi.love/img/solution-bg-2.webp" alt="" />
        </div>
        <div class="introduce-wrap">
            <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/solution-bg-3.png" alt="" />
            <div class="introduce font-bold">
                <div class="flex items-center justify-center">
                    <span class="text-dot"></span>
                    <span class="question text-14">{{ lang == 'en' ? 'Our vision' : '我們的願景' }}</span>
                </div>
                <div class="md:px-40">
                    <Language v-if="lang == 'en'" class="md:text-24 md:leading-30" text="VitalTech's core vision is redefinition[r1] global cross-border e-commerce experience[/r1]">
                        <span slot="r1" class="light-color" slot-scope="data">{{ data.value }}</span>
                    </Language>
                    <Language v-else class="md:text-24 md:leading-30" text="VitalTech的核⼼願景是重新定義[r1]全球跨境電商體驗[/r1]">
                        <span slot="r1" class="light-color" slot-scope="data">{{ data.value }}</span>
                    </Language>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Language from '@/components/common/Language';
import { mapState } from 'vuex';
export default {
    computed: {
        ...mapState({
            lang: state => state.lang,
        }),
    },
    components: {
        Language,
    },
};
</script>

<style lang="scss" scoped>
.c-solution {
    color: white;
    margin-top: 197px;
    margin: 197px 75px 100px 75px;

    .content {
        // max-width: 1770px;
        margin: 0 auto;
        position: relative;

        .title-info {
            max-width: 1920px;
            margin: 0 auto;
        }

        .bg-1 {
            position: absolute;
            right: -75px;
            z-index: 1;
            top: 20px;
        }

        .bg-1-m {
            position: absolute;
            top: 90px;
            right: 0;
        }

        .bg-2 {
            position: absolute;
            left: -75px;
            bottom: -180px;
            z-index: 1;

            @screen md {
                width: 240px;
                bottom: -180px;
                left: -20px;
            }
        }
    }

    .introduce-wrap {
        text-align: center;
        padding-top: 177px;

        img {
            margin: 0 auto;

            @screen md {
                width: 200px;
            }
        }

        .introduce {
            font-size: 54px;
            line-height: 68px;
            position: relative;
            top: -126px;
            max-width: 1280px;
            margin: 0 auto;

            .question {
                line-height: 22px;
            }

            .text-dot {
                width: 6px;
                height: 6px;
                display: block;
                border-radius: 6px;
                background-color: #253bff;
                margin-right: 7px;
            }

            .light-color {
                color: #253bff;
            }
        }
    }

    .dot {
        width: 6px;
        height: 6px;
        display: block;
        border-radius: 6px;
        background-color: #253bff;
        margin-right: 7px;
    }

    .title {
        font-size: 54px;
        line-height: 68px;
        margin-bottom: 40px;

        @screen md {
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 12px;
        }
    }

    .desc {
        font-size: 20px;
        line-height: 28px;
        color: rgba(255, 255, 255, 0.6);

        @screen md {
            font-size: 12px;
            line-height: 20px;
        }
    }

    .list-wrap {
        height: 593px;
        background-color: rgba(26, 23, 40, 0.8);
        border-radius: 20px;
        margin-top: 78px;
        padding: 75px 158px;
        max-width: 1920px;
        margin: 78px auto 0 auto;

        backdrop-filter: blur(45px);
        -webkit-backdrop-filter: blur(45px);
        position: relative;
        z-index: 2;

        @screen md {
            padding: 37px 28px;
            height: auto;
        }

        .item-content {
            display: flex;

            @screen md {
                display: block;
            }

            .items {
                flex: 1;

                img {
                    width: 60px;
                }
            }

            .item-title {
                font-size: 24px;
                font-weight: bold;
                line-height: 32px;
                color: white;
                margin-top: 20px;
                margin-bottom: 12px;

                @screen md {
                    font-size: 20px;
                }
            }

            .description {
                color: rgba(255, 255, 255, 0.6);
                font-size: 14px;
                max-width: 440px;

                @screen md {
                    font-size: 12px;
                }
            }
        }
    }
}
</style>
