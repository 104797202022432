import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/AllMedalWall',
  //   name: 'AllMedalWall',
  //   component: () => import("@/views/allMedalWall/AllMedalWall.vue")
  // },
  // {
  //   path: '/Community',
  //   name: 'Community',
  //   component: () => import("@/views/community/Community.vue")
  // },
  // {
  //   path: '/Data',
  //   name: 'Data',
  //   component: () => import("@/views/data/Data.vue")
  // },
  // {
  //   path:'/Swap',
  //   name: 'Swap',
  //   component: () => import("@/views/swap/Swap.vue")
  // },
  // {
  //   path:'/Asset',
  //   name: 'Asset',
  //   component: () => import("@/views/asset/Asset.vue")
  // },
  // {
  //   path:"/AssetDetail",
  //   name: 'AssetDetail',
  //   component: () => import("@/views/assetDetail/AssetDetail.vue")
  // },
  // PdfViewer
  // {
  //   path:"/pdfViewer/:type",
  //   name: 'pdfViewer',
  //   component: () => import("@/views/pdfViewer/index.vue")
  // }
]


const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 全局路由守卫
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  next()
})

export default router
