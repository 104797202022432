<template>
    <div class="c-partnership">
        <div class="productivity md:px-12">
            <div class="content">
                <div class="title">
                    VitalTech
                    <br />
                    {{ lang == 'en' ? 'Business model and operational strategy' : '業務模式與運營策略' }}
                </div>
                <div class="desc">Businesssegmentsandrevenue</div>
                <h3 class="abc">{{ lang == 'en' ? '1、GLOBAL E-COMMERCE SERVICES' : '1、全球電商服務' }}</h3>
                <div class="flex md:block">
                    <div class="list--item">
                        <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/test-net.png" alt="" />
                        <div class="item_text">
                            <span class="link" @click="toBlockChain">{{ lang == 'en' ? 'Market coverage and strategy' : '巿場覆蓋範圍與戰略' }}</span>
                            <span class="des">
                                {{ lang == 'en' ? 'Market coverage｜Market entry strategy｜User growth and retention' : '市場覆蓋範圍｜市場進⼊策略｜⽤⼾增⾧和保留' }}
                            </span>
                        </div>
                    </div>
                    <div class="list--item md:ml-0 md:mt-40">
                        <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/platform.png" alt="" />
                        <div class="item_text">
                            <span class="link" @click="toBlockChain">{{ lang == 'en' ? 'Ecosystem of co-brands and sellers' : '合作品牌與賣家⽣態系統' }}</span>
                            <span class="des">{{ lang == 'en' ? 'Brand cooperation｜Seller ecosystem｜Partner network' : '品牌合作｜賣家⽣態系｜合作夥伴網絡' }}</span>
                        </div>
                    </div>
                    <div class="list--item md:ml-0 md:mt-40">
                        <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/platform.png" alt="" />
                        <div class="item_text">
                            <span class="link" @click="toBlockChain">{{ lang == 'en' ? 'Global Logistics and supply chain Management' : '全球物流與供應鏈管理' }}</span>
                            <span class="des">
                                {{ lang == 'en' ? 'Global Logistics network｜Supply Chain Optimization｜Logistics tracking and transparency' : '全球物流網絡｜供應鏈優化｜物流追蹤與透明性' }}
                            </span>
                        </div>
                    </div>
                </div>
                <h3 class="abc" style="margin-top: 80px;">{{ lang == 'en' ? '2、ROSS-BORDER E-COMMERCE DIRECT SALES' : '2、跨境電商直銷' }}</h3>
                <div class="flex md:block">
                    <div class="list--item">
                        <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/test-net.png" alt="" />
                        <div class="item_text">
                            <span class="link" @click="toBlockChain">{{ lang == 'en' ? 'Membership system' : '會員製系統' }}</span>
                            <span class="des">
                                {{ lang == 'en' ? 'Member registration and management|Member services|Equity value and rewards' : '會員註冊與管理|會員服務|權益值和獎勵' }}
                            </span>
                        </div>
                    </div>
                    <div class="list--item md:ml-0 md:mt-40">
                        <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/platform.png" alt="" />
                        <div class="item_text">
                            <span class="link" @click="toBlockChain">{{ lang == 'en' ? 'Exclusive discounts and customized product recommendations' : '專屬折扣與定製化產品推薦' }}</span>
                            <span class="des">{{ lang == 'en' ? 'Exclusive discounts|Customized product recommendation|Personalized service' : '專屬折扣|定製化產品推薦|個性化服務' }}</span>
                        </div>
                    </div>
                    <div class="list--item md:ml-0 md:mt-40">
                        <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/platform.png" alt="" />
                        <div class="item_text">
                            <span class="link" @click="toBlockChain">{{ lang == 'en' ? 'Agent incentive mechanism' : '代理商激勵機製' }}</span>
                            <span class="des">
                                {{ lang == 'en' ? 'Commission and incentive plan:|Training and support|Performance evaluation and feedback' : '傭⾦和獎勵計劃|培訓和⽀持|業績評估與反饋' }}
                            </span>
                        </div>
                    </div>
                </div>
                <h3 class="abc" style="margin-top: 80px;">{{ lang == 'en' ? '3、CUSTOMIZED MARKETING AND DATA SOLUTIONS' : '3、定製營銷與數據解決⽅案' }}</h3>
                <div class="flex md:block">
                    <div class="list--item">
                        <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/test-net.png" alt="" />
                        <div class="item_text">
                            <span class="link" @click="toBlockChain">{{ lang == 'en' ? 'Personalized marketing strategy' : '個性化營銷策略' }}</span>
                            <span class="des">
                                {{ lang == 'en' ? 'User Behavior Analysis｜Precision advertising｜Dynamically adjust marketing campaigns' : '⽤⼾⾏為分析｜精準廣告投放｜動態調整營銷活動' }}
                            </span>
                        </div>
                    </div>
                    <div class="list--item md:ml-0 md:mt-40">
                        <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/platform.png" alt="" />
                        <div class="item_text">
                            <span class="link" @click="toBlockChain">{{ lang == 'en' ? 'Data analysis and optimization services' : '數據分析與優化服務' }}</span>
                            <span class="des">{{ lang == 'en' ? 'Data collection and analysis｜Optimize business decisions｜Personalized Service Optimization' : '數據收集與分析｜優化業務決策｜個性化服務優化' }}</span>
                        </div>
                    </div>
                    <div class="list--item md:ml-0 md:mt-40">
                        <img src="https://s3.us-west-1.amazonaws.com/agi.love/img/platform.png" alt="" />
                        <div class="item_text">
                            <span class="link" @click="toBlockChain">{{ lang == 'en' ? 'Merchant operation support and training' : '商家運營⽀持與培訓' }}</span>
                            <span class="des">
                                {{ lang == 'en' ? 'Technical support｜Marketing support｜Operation Training' : '技術⽀持｜市場推廣⽀持｜運營培訓' }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/Button.vue';
import { mapState } from 'vuex';
export default {
    components: {
        Button,
    },
    computed: {
        ...mapState(['lang']),
    },
    methods: {
        toPage(name) {
            this.$router.push({ name });
        },
        toBlockChain() {
            window.open('https://explorer.wodrpc.org/#/index');
        },
    },
};
</script>

<style lang="scss" scoped>
.c-partnership {
    color: white;
    position: relative;

    .partner-title {
        font-size: 54px;
        line-height: 68px;
        color: white;
        text-align: center;
        margin-top: 149px;
        margin-bottom: 50px;

        @screen md {
            font-size: 24px;
            line-height: 32px;
            margin-top: 130px;
        }
    }

    .partner-list {
        margin: 0 auto;
        max-width: 1920px;

        .list-content {
            display: flex;
            align-items: center;
            justify-content: space-around;
            border-bottom: 1px solid rgba(58, 61, 66, 0.5);
            padding-bottom: 110px;
        }

        @screen md {
            img {
                transform: scale(1) !important;
                max-height: 38px;
            }
        }

        @media screen and (min-width: 2070px) {
            padding: 0;
        }

        @media screen and (max-width: 1440px) {
            img {
                transform: scale(0.7);
            }
        }
    }

    .c-button {
        width: 240px;

        @screen md {
            width: 240px;
        }

        &:hover {
            width: 290px;

            @screen md {
                width: 280px;
            }
        }

        &.ru {
            width: 350px;

            &:hover {
                width: 400px;

                @screen md {
                    width: 350px;
                }
            }
        }
    }

    .productivity {
        padding: 93px 75px;
        box-sizing: border-box;
        max-width: 1920px;
        margin: 0 auto;

        @screen md {
            padding: 50px 12px;
        }

        .content {
            position: relative;
            z-index: 1;

            @screen md {
                // height: 544px;
                box-sizing: border-box;
            }
        }

        @media screen and (min-width: 2070px) {
            padding: 93px 0;
        }

        &::after {
            background-image: url('https://s3.us-west-1.amazonaws.com/agi.love/img/productivity-bg.webp');
            display: block;
            height: 730px;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            z-index: 0;
            background-repeat: no-repeat;
            background-size: cover;

            @screen md {
                height: 594px;
                background-image: url('https://s3.us-west-1.amazonaws.com/agi.love/img/productivity-bg-m.webp');
            }
        }

        .title {
            font-size: 54px;
            line-height: 68px;
            color: white;
            font-weight: bold;

            @screen md {
                font-size: 24px;
                line-height: 32px;
            }
        }

        .page-btn {
            display: flex;
            align-items: center;
            margin-top: 120px;
            @screen md {
                align-items: flex-start;
                flex-direction: column;
                margin-top: 80px;
            }
            .supply {
                margin-right: 73px;
                @screen md {
                    margin-right: 0px;
                    margin-bottom: 50px;
                }
            }
            .access {
                .c-button {
                    border-radius: 30px;
                    background: linear-gradient(to right, #0016dd, #4ce4f9);
                    &::after {
                        background: linear-gradient(to right, #4ce4f9, #0016dd);
                    }
                }
            }
        }

        .desc {
            font-size: 20px;
            line-height: 22px;
            color: rgba($color: #ffffff, $alpha: 0.6);
            margin-top: 12px;
            margin-bottom: 60px;

            @screen md {
                font-size: 12px;
                line-height: 20px;
                margin-bottom: 68px;
            }
        }

        .list--item {
            display: flex;
            align-items: center;
            font-size: 24px;
            line-height: 32px;
            max-width: 800px;
            flex: 1;
            position: relative;
            .item_text {
                display: flex;
                flex-direction: column;
            }
            .des {
                font-size: 16px;
                color: rgba(255, 255, 255, 0.7);
            }
            .link {
                cursor: pointer;
            }

            @screen md {
                padding-bottom: 20px;
            }

            &::after {
                display: block;
                content: '';
                height: 1px;
                background-color: rgba(255, 255, 255, 0.4);
                position: absolute;
                left: 0;
                right: 0;
                bottom: -30px;

                @screen md {
                    bottom: 0;
                }
            }

            & + .list--item {
                margin-left: 80px;
            }

            img {
                margin-inline-end: 31px;
            }
        }
    }
}
.abc {
    color: #fff;
    font-size: 30px;
    margin-bottom: 20px;
    @screen md {
        font-size: 24px;
    }
}
</style>
